<template>
  <div>
    <div class="school" v-if="$store.state.pc">
      <div class="header">
        <baseHead :bgcClo="$store.state.themeOther.color" :dataList="list_data.modular" @tabs="tabs"
          :currentIndex="currentIndex" :banner="banner" :title="$route.query.name"></baseHead>
      </div>
      <div class="content">
        <div class="content_text">
          <div class="content_text_left">
            <img :src="list_data.up.img" />
          </div>
          <div class="content_text_right">
            <div>
              <div class="title" style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                " v-for="(item, index) in pageContent" :key="index" @click="vie(item)">
                <span class="bor">{{ index + 1 + pageIndex * 5 - 5 }}</span>
                <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                  <span class="title_text">{{ item.name }}</span>
                </el-tooltip>
              </div>
            </div>
            <div class="page">
              <el-pagination layout="prev, pager, next" :total="list_data.up.children.length" :page-size="5"
                @current-change="page_current">
              </el-pagination>
            </div>
          </div>
        </div>
        <div class="achievements">
          <div class="achievements_title" v-if="list_data.down.children && list_data.down.children.length > 0">
            <p>{{ list_data.down.title }}</p>
            <span></span>
          </div>
          <div class="achievements_img" v-if="list_data &&
      list_data.down &&
      list_data.down.children &&
      list_data.down.children.length > 4
      ">
            <el-carousel :interval="4000" type="card" height="300px">
              <el-carousel-item v-for="(item, index) in list_data.down.children" :key="index">
                <img :src="item.url" />
                <!-- <img src="../assets/微信图片_20211025024428.jpg"> -->
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="img_list" v-else>
            <ul>
              <li v-for="(item, index1) in list_data.down.children" :key="index1">
                <img :src="item.url" alt="" />
              </li>
            </ul>
          </div>
        </div>
        <basecopyRight></basecopyRight>

        <el-dialog title="验证" :visible.sync="vierdiong" width="30%">
          <span>这是一段信息</span>
          <el-input v-model="vier"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="vierdiong = false">取 消</el-button>
            <el-button type="primary" @click="curr">确 定</el-button>
          </span>
        </el-dialog>

        <el-dialog title="内容" :visible.sync="dialogVisible" width="80%">
          <iframe :src="vir_url" frameborder="0" width="100%" height="500px"></iframe>
          <div></div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
    <div class="school" v-else>
      <el-dialog title="验证" :visible.sync="vierdiong" width="80%">
        <span>这是一段信息</span>
        <el-input v-model="vier"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="vierdiong = false">取 消</el-button>
          <el-button type="primary" @click="curr">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="内容" :visible.sync="dialogVisible" width="90%">
        <div class="iframe_div">
          <iframe :src="vir_url" frameborder="0" width="100%" height="500px"></iframe>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <div class="head">
        <BaseMobHead :bgcClo="$store.state.themeOther.color" :dataList="list_data.modular" :currentIndex="currentIndex"
          :banner="banner" @mobopen="mobopen" :title="$route.query.name"></BaseMobHead>
      </div>
      <div class="content">
        <div class="list">
          <div class="list_img">
            <div class="list_img_text">
              <p v-if="this.$route.paht == '/nhwx/three'">
                {{ list_data.up.title }}
              </p>
              <p v-if="this.$route.paht == '/nhwx/three'"></p>
            </div>
            <img :src="list_data.up.img" />
          </div>
          <div class="list_text">
            <p v-for="(item, index) in list_data.up.children" :key="index" @click="vie(item)">
              <span></span><span>{{ item.name }}</span>
            </p>
          </div>
        </div>
        <div class="show">
          <p>{{ list_data.down.title }}</p>
          <div class="line"></div>
          <div class="swipe">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" height="300px">
              <van-swipe-item v-for="(item, index) in list_data.down.children" :key="index"><img :src="item.url"
                  @click="gocontent(item)" /></van-swipe-item>
            </van-swipe>
          </div>
        </div>
      </div>
      <basecopyRight></basecopyRight>
    </div>
  </div>
</template>

<script>
import { index_list, banner_list, twice_list, check } from "../api/api";
import baseHead from "../../src/components/Public/baseHead/baseHead.vue";
import basecopyRight from "../components/Public/BasecopyRight/basecopyRight.vue";
import BaseMobHead from "../components/Public/BaseMobHead/BaseMobHead.vue";
export default {
  components: {
    baseHead,
    BaseMobHead,
    basecopyRight,
  },
  created() {
    this.getData();
    // this.color();
  },
  mounted() {
    window.scrollTo(0, 0);
  },

  data() {
    return {
      dialogVisible: false, //内容
      vier: "",
      vir_url: "",
      vierdiong: false, //验证
      vierdiongcontent: false, //内容
      current_nav: {}, //当前栏目
      banner: [], //banner图数据
      list_data: {
        down: { children: [] },
        up: {
          children: [],
        },
        modular: {},
      }, //当前页面渲染数据
      currentIndex: 0, //当前nav下表
      pageIndex: 1, //分页页数
      pageContent: [], //分页内容
      project_id: "",
    };
  },
  methods: {
    // color() {

    //   if (localStorage.getItem('themeOther') && localStorage.getItem('themeOther').color) {

    //     document
    //       .getElementsByTagName("body")[0]
    //       .style.setProperty("--test", localStorage.getItem('themeOther').color);
    //   }

    // },
    // 当前页
    page_current(index) {
      this.pageContent = [];
      this.pageIndex = index;
      let min = 0;
      let max = 4;
      min = index * 5 - 5;
      max = index * 5 - 1;
      this.list_data.up.children.forEach((item, index1) => {
        if (index1 >= min && index1 <= max) {
          this.pageContent.push(item);
        }
      });
      console.log(this.pageContent);
    },
    // tabs切换
    tabs(value) {
      // 掉接口
      this.currentIndex = value.index;
      this.current_nav = value.item;
      if (value.index === 0) {
        this.$router.push({
          path: `/${localStorage.getItem("urlL")}`,
          query: this.$route.query,
        });
      } else if (value.index === 1) {
        this.$router.push({ path: `/${localStorage.getItem("urlL")}` });
      } else {
        this.$router.push({
          path: `/${localStorage.getItem("urlL")}/contentThree`,
          query: {
            modular_id: value.item.id,
            project_id: value.item.project_id,
            name: this.$route.query.name,
          },
        });
      }
    },
    // 跳转页面
    gocontent(item) {
      console.log(item);
      this.$router.push({
        path: `/${localStorage.getItem("urlL")}/contentThree`,
        query: {
          modular_id: item.id,
          project_id: item.project_id,
          name: this.$route.query.name,
        },
      });
    },
    // 弹框验证
    vie(item) {
      this.project_id = item.id;
      if (item.pwd_type) {
        // 有密码
        this.vierdiong = true;
      } else {
        // 没密码
        this.vir_url = item.url;
        // this.dialogVisible = true;
        if (this.$store.state.mob) {
          localStorage.setItem("preview_pdf", item.url);
          this.$router.push({ path: "/pdf" });
        } else {
          this.dialogVisible = true;
        }
      }
    },
    // 验证密码
    curr() {
      check({ file_id: this.project_id, password: this.vier }).then((res) => {
        if (res.data.code == 200) {
          console.log(res);
          this.vir_url = res.data.url;
          this.vierdiong = false;
          // this.dialogVisible = true;
          localStorage.setItem("preview_pdf", item.url);
          this.$router.push({ path: "/pdf" });
        } else {
          this.$message.error("验证码错误");
          this.vierdiong = false;
        }
      });
      this.vierdiong = false;
      this.vier = "";
    },
    getData() {
      if (this.$route.query && this.$route.query.project_id) {
        index_list({
          zl_url: this.$store.state.roleSchool,
          project_id: this.$route.query.project_id,
        }).then((res) => {
          res.data.data.modular = [
            { name: "专栏首页" },
            { name: "首页" },
            ...res.data.data.modular,
          ];
          this.list_data = res.data.data;
          this.list_data.up.children.forEach((item, index1) => {
            if (index1 >= 0 && index1 <= 4) {
              this.pageContent.push(item);
            }
          });

          console.log(this.list_data);
        });
        banner_list({ project_id: this.$route.query.project_id }).then(
          (res1) => {
            this.banner = res1.data.data;
          }
        );
      }
    },
    mobopen(value) {
      this.currentIndex = value - 1;
      let modular = this.list_data.modular[this.currentIndex];
      console.log(modular);
      if (this.currentIndex == 0) {
        this.$router.go(-1);
      } else if (this.currentIndex == 1) {
        this.$router.push({ path: `/${localStorage.getItem("urlL")}` });
      } else {
        this.$router.push({
          path: `/${localStorage.getItem("urlL")}/contentThree`,
          query: {
            name: this.$route.query.name,
            project_id: modular.project_id,
            modular_id: modular.id,
          },
        });
      }
    },
    // frame在移动端上显示时不能全部展示完全
    setIframeHeight(id) {
      try {
        var iframe = document.getElementById(id);
        if (iframe.attachEvent) {
          iframe.attachEvent("onload", function () {
            iframe.height =
              iframe.contentWindow.document.documentElement.scrollHeight;
          });
          return;
        } else {
          iframe.onload = function () {
            iframe.height = iframe.contentDocument.body.scrollHeight;
          };
          return;
        }
      } catch (e) {
        console.warn("setIframeHeight Error");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../src/style/default/default.scss";

.pc {
  .school {
    background: white;
    position: relative;

    .header {
      height: 356px;
    }

    .content {
      background: white;
      width: 1400px;
      padding-bottom: 20px;
      margin: 0 auto;

      .content_text {
        padding-top: 30px;
        height: 200px;

        .content_text_left {
          width: 480px;
          height: 100%;
          position: relative;
          float: left;

          img {
            width: 100%;
            height: 100%;
          }

          p {
            position: absolute;
            left: 50%;
            color: white;
            font-size: 18px;
            top: 50%;
            margin: 0;
            transform: translate(-50%);
          }
        }

        .content_text_right {
          position: relative;
          width: 710px;
          height: 100%;
          float: left;
          margin-left: 100px;

          .title {
            margin-bottom: 5px;
            font-size: 18px;

            &:hover {
              cursor: pointer;
            }

            .bor {
              color: $brown;
              font-weight: bold;
              padding: 0 15px;
              border-right: 1px solid $brown;
            }

            .title_text {
              color: #333333;
              font-size: 18px;
              padding-left: 15px;
            }
          }

          .page {
            position: absolute;
            display: inline-block;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            /deep/.active {
              color: $brown;
            }

            .el-pagination {
              display: inline-block;
            }
          }
        }
      }

      .achievements {
        margin-top: 60px;

        .achievements_title {
          text-align: center;
          font-size: 20px;
          color: #333333;

          p {
            margin: 0;
          }

          span {
            display: inline-block;
            width: 50px;
            height: 4px;
            background: $brown;
          }
        }

        .achievements_img {
          margin-top: 30px;
          height: 300px;

          img {
            height: 100%;
            width: 100%;
          }
        }

        .img_list {
          margin-top: 20px;
          width: 100%;

          ul {
            height: 200px;
            width: 100%;

            li {
              float: left;
              height: 100%;
              width: 330px;
              margin-right: 20px;

              &:nth-child(4) {
                margin-right: 0;
              }

              img {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }

    .foot {
      width: 100%;
    }
  }
}

.mob {
  box-sizing: border-box;
  padding: 15px;

  .iframe_div {
    -webkit-overflow-scrolling: touch;
    /* 当手指从触摸屏上移开，会保持一段时间的滚动 */
    overflow-y: scroll;
  }

  .school {
    overflow: hidden;
  }

  .content {
    overflow: hidden;
    color: #333333;
    background: #f5f5f5;
    margin-top: 30px;

    .list {
      width: 100%;

      .list_img {
        background-size: 100%;
        width: 100%;
        height: 9.375rem;
        z-index: 0;

        img {
          box-sizing: content-box;
          width: 100%;
          height: 100%;
        }

        .list_img_text {
          color: white;
          position: relative;

          p {
            margin: 0;
            text-align: center;
            position: absolute;
            top: 1.875rem;
            left: 50%;
            transform: translate(-50%);

            &:nth-child(2) {
              position: absolute;
              height: 6px;
              width: 60px;
              left: 50%;
              transform: translateX(-50%);
              top: 6rem;
              text-align: center;
              background: white;
            }
          }

          font-size: 2.25rem;
          font-weight: bold;
        }
      }

      .list_text {
        p {
          margin: 10px 0;
          padding: 0 30px;
          height: 3.125rem;
          line-height: 3.125rem;
          position: relative;

          span {
            &:nth-child(1) {
              position: absolute;
              z-index: 1;
              left: 14px;

              top: 50%;
              transform: translateY(-50%);
              display: inline-block;
              width: 10px;
              height: 10px;
              background: $brown;
              border-radius: 50%;
            }

            &:nth-child(2) {
              margin-left: 10px;
              color: #333333;
              display: inline-block;
              width: 80%;
              white-space: nowrap;
              /*超出的空白区域不换行*/
              overflow: hidden;
              /*超出隐藏*/
              text-overflow: ellipsis;
              /*文本超出显示省略号*/
              font-size: 2rem;
            }
          }
        }
      }
    }

    .show {
      position: relative;
      margin-top: 30px;

      p {
        margin: 10px 0;
        text-align: center;
        font-size: 2.25rem;
      }

      .line {
        width: 4.375rem;
        height: 0.375rem;
        background: $brown;
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 3.75rem;
        transform: translateX(-50%);
      }

      .swipe {
        position: relative;
        margin-top: 30px;

        .my-swipe {
          margin-left: 10%;
          width: 80%;
        }

        img {
          width: 100%;
          border: 1px solid #cccccc;
          height: 18.75rem;
        }
      }
    }
  }
}
</style>
